import React from "react"
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react"
import {MDXRenderer} from "gatsby-plugin-mdx"
import Card from "@material-ui/core/Card";
import components from "../components/mdx_components";
import GatsbyImage from "gatsby-image";
import SEO from "../components/seo";
import EventsCard from "../components/EventsCard";

const IndexPage = ({data}) => {

  const events = data.events.nodes.filter(x => new Date(x.frontmatter.date) > new Date())
  const nextEvent = events.length < 1 ? null : events[0]

  return (
    <React.Fragment>
      <SEO title="Home"/>
      <GatsbyImage fluid={data.file.childImageSharp.fluid}
                   imgStyle={{objectFit: 'cover'}}
                   style={{maxHeight: '600px'}}/>
      <Container maxWidth="md">
        <Box my={1}>
          <Card>
            <Box m={1}>
              <MDXProvider components={components}><MDXRenderer>{data.mdx.body}</MDXRenderer></MDXProvider>
            </Box>
          </Card>
        </Box>
        {nextEvent !== null ? (
          <Box my={1}>
            <EventsCard events={[nextEvent]}/>
          </Box>
        ) : null}
      </Container>
    </React.Fragment>
  )
}

export default IndexPage
export const pageQuery = graphql`
    query {
        mdx(frontmatter: { title: { eq: "Steckbrief" } }) {
            body
        }
        file(relativePath: { eq: "images/title_image.png" }) {
            childImageSharp {
                fluid(maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        events: allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {slug: {regex: "/events/"}}) {
            nodes {
                id
                body
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    time: date(formatString: "HH:mm")
                    title
                    location
                    price
                }
            }
        }
    }
`
